* {
    margin: 0;
    padding: 0;
    border: 0;
}

:root {
    font-family: "Barlow Condensed", "Segoe UI", Tahoma, Geneva, Verdana,
        sans-serif;
    color: #d2b48c;
    background-color: #264646;
}

.app {
    display: grid;
    grid-template-areas:
        "header"
        "cont"
        "footer";
    grid-template-rows: 10vh minmax(0, 1fr) 10vh;
    min-height: 100vh;
    width: 100vw;
}

.sticky {
    position: sticky;
    top: 0;
}

.nav {
    grid-area: header;
    background-color: rgb(38, 70, 70);
    z-index: 2;
}
.choice,
.cont {
    grid-area: cont;
    grid-template-rows: 1fr;
}
.footer {
    grid-area: footer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
}

a {
    color: tan;
    text-decoration: none;
}

p a {
    color: skyblue;
}

p a.light-mode {
    color: blue;
}

.store-info p, .fecolorz-info p {
    margin: 10px;
}

.nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bolder;
    width: 100vw;
    font-size: 1rem;
}
.nav-links-left {
    margin: 25px;
}
.nav-home.light-mode,
.nav-about.light-mode {
    color: black;
}
.nav-links-right {
    display: flex;
    align-items: center;
}
.nav-links-left img {
    background-color: transparent;
}
.nav-home {
    margin-right: 25px;
}
.nav-button,
.nav-button.light-mode {
    padding-right: 20px;
    max-width: 20px;
    max-height: 20px;
    background-color: transparent;
    transition: filter 1s ease-in;
}
.nav-button {
    height: auto;
    width: auto;
}

.social img {
    filter: invert(50%);
    height: 15px;
    width: 15px;
    margin: 0 8px;
}
.social img:active {
    filter: invert(100%);
}
.social.light-mode * {
    filter: none;
}

.light-mode {
    color: #3a2912;
    background-color: #d2b48c;
}

.nav-title {
    padding-right: 20px;
}
.nav-title.light-mode {
    color: teal;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    font-size: 0.75rem;
}

.main h1 {
    margin: 25px;
}

.mathical-video,
.vm-video {
    width: 100%;
    height: 100%;
}

.video-frame {
    width: 50vw;
}

.mathical-info,
.vm-info {
    margin-top: 25px;
}

.mathical-list,
.vm-list {
    list-style-type: none;
    margin-top: 10px;
}

.store-screenshot, .fecolorz-screenshot {
    width: 50%;
    margin: 25px 0;
}

.choices-list {
    display: flex;
    flex-direction: column;
}

.choices-project {
    display: flex;
    justify-content: center;
    align-items: center;
}
.choices-project:active {
    filter: brightness(0.5);
}

.choices-text {
    font-family: "Bebas Neue", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3em;
    background-color: transparent;
    position: absolute;
    color: white;
    letter-spacing: 8px;
    text-shadow: 3px 3px black;
    text-align: center;
    transition: color 0.25s, letter-spacing 0.25s ease-in;
    white-space: nowrap;
}

.choices-text2 {
    font-size: 2em;
    margin-top: 3em;
}

.choices-text:active,
.choices-text2:active {
    color: black;
}

.choices-link {
    width: 100%;
}

.choices-img {
    width: 100%;
    height: 26.6vh;
    object-fit: cover;
}

.in-progress {
    filter: opacity(0.5) brightness(0.5);
}

.about-me {
    display: block;
    width: 50vw;
}

figcaption {
    color: #fff;
    font: italic smaller sans-serif;
    margin-bottom: 25px;
    text-align: center;
}

figcaption.light-mode {
    color: black;
}

.about-intro {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    background-color: #73b2d9;
    color: black;
    padding: 5%;
}

.about-intro2,
.about-intro4 {
    font-size: 2rem;
    background-color: #006eb3;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.brickboy,
.me-camera {
    margin: 0;
}

.about-main img {
    top: auto;
}
.about-photo {
    margin: auto;
}

.intro {
    font-style: italic;
}

.about-intro3,
.about-intro5 {
    background-color: #ffbb00;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.about-intro5 p {
    font-size: 2em;
}

.about-intro6 {
    display: flex;
    font-size: 2em;
    left: 50%;
    font-family: arial;
    justify-content: center;
    background-color: black;
    font-family: "Dongle", sans-serif;
    color: white;
    font-weight: bolder;
    letter-spacing: 1px;
    word-spacing: 2px;
    padding: 10%;
}

.about-intro6.light-mode {
    background-color: white;
    color: black;
}

[class^="about-text"] p {
    padding: 0 5%;
}

.about-text-left {
    color: black;
    margin: auto;
}
.about-text-right {
    margin: auto;
}

.about-intro.light-mode {
    color: black;
}

.footer-note {
    margin-top: 50px;
}

.footer img {
    max-width: 25px;
    min-width: 12px;
    padding: 0 10px;
}

.social {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-right: 25px;
    margin-top: 4px;
}

.social-links {
    text-align: left;
}

.font-2 {
    font-size: 2rem;
}

.font-4 {
    font-size: 4rem;
}

.clr-white {
    color: white;
}

.font-bold {
    font-weight: bold;
}

.font-bolder {
    font-weight: bolder;
}

.font-italic {
    font-style: italic;
}

.text-name,
.text-code,
.text-notch {
    color: #ffff00;
}
.text-fsd,
.text-camera,
.text-tech {
    color: #94ffef;
}
.text-notch.light-mode,
.text-tech.light-mode {
    background-color: white;
}
.text-location {
    color: #ffe8b8;
}
.text-superpower,
.text-world,
.text-tech.light-mode,
.text-notch.light-mode {
    color: #00567a;
}

.play-button {
    display: inline-block;
    background-color: green;
    padding: 25px;
    margin: 25px;
    font-weight: bold;
    color: black;
}
.play-button:active {
    right: 0;
    bottom: 0;
    box-shadow: inset 2px 3px 4px black;
    color: yellow;
}

@media only screen and (min-width: 600px) {
    :root {
        overflow-x: hidden;
    }
    .main {
        align-items: normal;
        font-size: 1.5rem;
    }
    .nav-wrapper {
        font-size: 1.5rem;
    }
    .nav-links-left a {
        transition: color 0.25s ease-in;
    }
    .nav-links-right {
        margin-right: 25px;
    }

    a {
        color: #6c4f14;
        text-decoration: none;
    }

    .app {
        width: 100vw;
    }

    .nav-links-left.light-mode a:hover {
        color: teal;
    }
    .nav-links-left a:hover {
        color: tan;
    }

    nav-button {
        height: 10px;
        width: 10px;
    }

    .nav-button:hover {
        filter: invert(1);
    }

    .nav-links-left .light-mode:hover {
        transition: color 0.25s ease-in;
    }

    .video-frame {
        width: 600px;
        height: 400px;
    }

    .choices-project:hover {
        cursor: pointer;
    }

    .choices-text {
        color: transparent;
        text-shadow: none;
    }

    .choices-project:hover .choices-text {
        color: white;
        letter-spacing: 5px;
    }

    .in-progress:hover {
        filter: opacity(0.5);
    }
    .choices-img {
        transition: height 0.25s ease-in;
    }
    .choices-img:hover {
        height: 50vh;
    }
    .choices-img.in-progress:hover {
        height: 26.6vh;
    }

    .about-intro {
        display: flex;
        flex-direction: column;
        background-color: #73b2d9;
        color: black;
        padding: 5%;
    }

    .about-introduction {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .intro p {
        font-size: 3rem;
    }

    .about-me {
        width: 425px;
        margin: auto;
    }

    figcaption {
        color: gray;
    }
    figcaption a {
        color: cadetblue;
    }

    .about-intro3 p,
    .about-intro5 p {
        width: 25%;
        color: black;
    }

    .about-intro3 p {
        font-size: 1.5rem;
    }
    .about-intro3-img {
        margin-left: 50px;
    }
    .me-camera,
    .me-game {
        width: 275px;
    }

    .about-intro5 p {
        font-size: 2rem;
    }
    .about-intro2 p,
    .about-intro3 p,
    .about-intro4 p,
    .about-intro5 p,
    .about-intro6 p {
        width: 50vw;
        margin: 10% 0;
    }

    .about-intro6 p {
        font-size: 3rem;
        padding: 0;
        margin: 0;
    }

    .about-photo {
        width: 30vw;
    }

    .social.light-mode * {
        filter: none;
    }

    .social img {
        height: 25px;
        width: 25px;
        margin: 0 10px;
    }

    .social img:hover {
        filter: invert(100%);
    }
    .social img:active {
        filter: blur(2px);
    }

    .play-button:hover {
        position: relative;
        right: 5px;
        bottom: 5px;
        box-shadow: 10px 10px black;
        color: white;
    }

    .intro {
        padding: 25px;
        margin: auto;
    }
}
